import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import {
  authStore,
  useZStore,
  accountPanelStore,
  guidedTourStore,
} from "./store";

// Implementation of dynamic layout by fetching from remote server

// let layout = `<single-spa-router><div class="loading" id="loading-layout">
//     <div class="spinner animate-spin"></div>
//     <span>Loading</span>
//   </div></single-spa-router>`;

// let routes = constructRoutes(layout, {
//   loaders: {
//     navigation: `<div class="loading">
//     <div class="spinner animate-spin"></div>
//     <span>Loading</span>
//   </div>`,
//     route: `<div class="loading loading-route">
//     <div class="spinner animate-spin"></div>
//     <span>Loading</span>
//   </div>`,
//   },
//   props: {},
// });

// let applications = constructApplications({
//   routes,
//   loadApp({ name }) {
//     return System.import(name);
//   },
// });

// let layoutEngine = constructLayoutEngine({ routes, applications });

// applications.forEach((application) => {
//   registerApplication({
//     name: application.name,
//     app: application.app,
//     activeWhen: application.activeWhen,
//     customProps: {
//       authStore: authStore,
//       useZStore: useZStore,
//       accountPanelStore: accountPanelStore,
//     },
//   });
// });

// layoutEngine.activate();
// start();

// const loadLayout = async () => {
//   const res = await fetch("http://localhost:7000");
//   const remoteLayoutTemplate = await res.text();

//   const res2 = await fetch("http://localhost:7000/routes.json");
//   const remoteLayoutObject = await res2.json();
//   authStore.setState({ layoutObject: remoteLayoutObject });

//   const remoteLayout = remoteLayoutTemplate.replace("{{router}}", remoteLayoutObject.map((layout) => {
//     return `<route  ${layout.path === "/" ? "default" : `path="${layout.path}"`}>
//       <application name="${layout.name}" loader="route"></application>
//     </route>`;
//   }).join(""));

//   routes = constructRoutes(remoteLayout, {
//     loaders: {
//       navigation: `<div class="loading">
//       <div class="spinner animate-spin"></div>
//       <span>Loading</span>
//     </div>`,
//       route: `<div class="loading loading-route">
//       <div class="spinner animate-spin"></div>
//       <h6>Loading</h6>
//     </div>`,
//     },
//     props: {},
//   });

//   applications = constructApplications({
//     routes,
//     loadApp({ name }) {
//       return System.import(name);
//     },
//   });

//   let newLayoutEngine = constructLayoutEngine({ routes, applications });

//   applications.forEach((application) => {
//     registerApplication({
//       name: application.name,
//       app: application.app,
//       activeWhen: application.activeWhen,
//       customProps: {
//         authStore: authStore,
//         useZStore: useZStore,
//         accountPanelStore: accountPanelStore,
//       },
//     });
//   });

//   layoutEngine.deactivate();
//   document.getElementById("loading-layout").remove();
//   newLayoutEngine.activate();
//   start();
// };

// loadLayout();

// Implementation of dynamic layout by loading object from environment variable
const layoutObject = authStore.getState().layoutObject
  ? authStore.getState().layoutObject
  : process.env.LAYOUT_OBJECT
  ? (JSON.parse(process.env.LAYOUT_OBJECT) as {
      name: string;
      path: string;
    }[])
  : [];

const layoutTemplate =
  authStore.getState().layoutString || process.env.LAYOUT_TEMPLATE_STRING;

const layout = layoutTemplate.replace(
  "{{router}}",
  layoutObject
    .map((layout) => {
      return `<route  ${
        layout.path === "/" ? "default" : `path="${layout.path}"`
      }>
    <application name="${layout.name}" loader="route"></application>
  </route>`;
    })
    .join("")
);

let routes = constructRoutes(layout, {
  loaders: {
    navigation: `<div class="loading">
    <div class="spinner animate-spin"></div>
    <span>Loading</span>
  </div>`,
    route: `<div class="loading loading-route">
    <div class="spinner animate-spin"></div>
    <span>Loading</span>
  </div>`,
  },
  props: {},
});

let applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

let layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach((application) => {
  registerApplication({
    name: application.name,
    app: application.app,
    activeWhen: application.activeWhen,
    customProps: {
      authStore: authStore,
      useZStore: useZStore,
      accountPanelStore: accountPanelStore,
      guidedTourStore: guidedTourStore,
    },
  });
});

layoutEngine.activate();
start();
